import { CurrencyComponent } from '../lib/types.local';
import CoinsIcon from './CoinsIcon';

const Coins: CurrencyComponent = ({ amount, className }) => {
  return (
    <div className={`flex items-center ${className || ''}`}>
      <CoinsIcon className="mr-2" />
      {amount.toLocaleString()}
    </div>
  );
};

export default Coins;
