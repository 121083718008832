import React from 'react';

import closeSrc from '../assets/icons/x-close-small.svg';
import { _ } from '../lib/l18n';

const typeClasses = {
  success: 'bg-green-lime-20 border-green-lime',
  error: 'bg-red-pale-2 border-red',
};

type Props = { showClose?: boolean; onClose?: () => void; type?: keyof typeof typeClasses };

const Notification: React.FC<Props> = ({ children, onClose, showClose = true, type = 'success' }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClose && onClose();
  };
  const classes = typeClasses[type];
  return (
    <div className={`${classes} border rounded  text-gunmetal text-sm flex py-3 pl-6`} aria-live="assertive">
      <div className={`flex-1 ${showClose ? '' : 'mr-6'}`}>{children}</div>
      {showClose ? (
        <div className="flex-grow-0">
          <a href="#close" role="button" onClick={handleClick} className="px-3 flex items-center justify-center">
            <img src={closeSrc} alt={_('close')} />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export const NotificationError: React.FC<Props> = (props) => {
  return <Notification {...props} type="error" />;
};

export const NotificationSuccess = Notification;

export default Notification;
