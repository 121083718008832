import React from 'react';
import Coins from './Coins';
import { Link } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import { CurrencyComponent } from '../lib/types.local';

const ItemTile: React.FC<{
  itemUrl: string;
  type: string;
  name: string;
  image: string;
  cost: number;
  costSuffix?: string;
  tag?: string;
  info?: string;
  goal?: number;
  goalProgress?: number;
  highlightInfo?: boolean;
  currencyComponent?: CurrencyComponent;
}> = ({
  itemUrl,
  type,
  name,
  image,
  cost,
  costSuffix,
  tag,
  info,
  goal,
  goalProgress,
  highlightInfo,
  currencyComponent: Currency = Coins,
}) => {
  return (
    <div className="pl-6 md:w-1/3 lg:w-1/4 w-full">
      <Link
        to={itemUrl}
        className="h-full hover:no-underline text-grey border-2 rounded border-grey-10 shadow-item py-2 px-4 pb-4 flex flex-col items-center"
      >
        <div className="pb-1">{type}</div>
        <div className="pb-2 max-w-full">
          <div className="rounded relative border-1 border-grey-10 max-w-full" style={{ width: '208px', height: '208px' }}>
            <div
              className="rounded w-full h-full bg-center bg-cover"
              style={{ backgroundImage: `url("${encodeURI(image)}")` }}
            ></div>
            {tag ? (
              <div
                title={tag}
                className="px-4 py-1 absolute bg-grey-60 text-sm text-white truncate rounded-br bottom-0 right-0"
                style={{ maxWidth: '80%' }}
              >
                {tag}
              </div>
            ) : null}
          </div>
        </div>
        <div className="font-medium pb-3 truncate max-w-full" title={name}>
          {name}
        </div>
        <div className="flex flex-row justify-center items-center">
          <Currency amount={cost} className="leading-none" />
          {costSuffix ? <div className="capitalize ml-2">{costSuffix}</div> : null}
        </div>
        {goal ? (
          <div className="flex mt-3">
            <div className="w-48 max-w-full px-4">
              <ProgressBar progress={goalProgress || 0} />
            </div>
          </div>
        ) : null}
        {info ? (
          <div className="flex-grow flex pt-4 items-center">
            <div className={`ttext-center text-sm ${highlightInfo ? 'rounded-lg py-1 px-4 bg-grey-5' : 'text-grey-slate'}`}>
              <span className={`${highlightInfo ? 'opacity-90' : ''}`}>{info}</span>
            </div>
          </div>
        ) : null}
      </Link>
    </div>
  );
};

export default ItemTile;
