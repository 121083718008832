import React from 'react';
import { useBrandingStore } from '../state/store';
import PoweredBy from './PoweredBy';

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  const { logo } = useBrandingStore();
  return <footer className="my-4">{logo ? <PoweredBy /> : null}</footer>;
};

export default Footer;
